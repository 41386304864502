import React from 'react';
import { BoundedContent } from '@vp/swan';
import {
  HtmlAttributes,
  RootLayout,
  SwanStyleLoader,
} from '@vp/digital-site-layout-lib';
import { AuthCallbackProvider } from '@vp/digital-auth-lib';
import { RuntimeContextProvider } from '@vp/digital-environment-lib';
import { LocaleProvider } from '@vp/digital-locale-lib';
import { swanStyleKeys } from './swanKeys';
import { ContextModel as IndexContextModel } from './models';
import Hero from './Hero';

import './Index.scss';

interface IndexProps {
  pageContext: IndexContextModel;
}

const Index = ({
  pageContext: { mfeName, pageIndexPath, setPathSuffix, getEnvironment },
}: IndexProps) => {
  return (
    <LocaleProvider allowDefault={true}>
      <RuntimeContextProvider getEnvironment={getEnvironment}>
        <AuthCallbackProvider>
          <SwanStyleLoader />
          <RootLayout swanStyleKeys={swanStyleKeys}>
            <HtmlAttributes />
            <BoundedContent>
              <Hero
                mfeName={mfeName}
                pageIndexPath={pageIndexPath}
                setPathSuffix={setPathSuffix}
              />
            </BoundedContent>
          </RootLayout>
        </AuthCallbackProvider>
      </RuntimeContextProvider>
    </LocaleProvider>
  );
};

export default Index;
