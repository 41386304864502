import React from 'react';
import { HeroBackground } from '@vp/digital-hero-lib';
import {
  StandardHero,
  StandardHeroTextContainer,
  StandardHeroText,
  StandardHeroTextInner,
  StandardHeroHeading,
  StandardHeroDescription,
  Button,
  Link,
} from '@vp/swan';
import { usePathSuffix } from '@vp/digital-environment-lib';

interface HeroProps {
  mfeName: string;
  pageIndexPath: string;
  setPathSuffix: boolean;
}

const Hero = ({ mfeName, pageIndexPath, setPathSuffix }: HeroProps) => {
  const pathSuffix = usePathSuffix();

  return (
    <StandardHero
      textHorizontalAlign="center"
      textVerticalAlign="center"
      textBackground="standard"
      skin="standard"
      fullWidthImage
      height="short"
    >
      <StandardHeroTextContainer>
        <StandardHeroText>
          <StandardHeroTextInner bgc="white">
            <StandardHeroHeading>{mfeName} Page Index</StandardHeroHeading>
            <StandardHeroDescription>
              <Link href={`${pageIndexPath}${setPathSuffix ? pathSuffix : ''}`}>
                <Button skin="primary" size="mini" width="standard">
                  Page Index
                </Button>
              </Link>
            </StandardHeroDescription>
          </StandardHeroTextInner>
        </StandardHeroText>
      </StandardHeroTextContainer>
      <HeroBackground backgroundClassName="index-hero__background" />
    </StandardHero>
  );
};

export default Hero;
